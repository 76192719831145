<template lang="pug">
  div
    v-dialog(v-model='dialogSaveFac' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | DATOS DE PAGO
        v-row(style="padding: 20px;")
          v-col(cols='12' md="6")
            v-text-field.purple-input(label="Valor a Pagar" v-model="total" readonly)
          v-col(cols='12' md="6")
            v-text-field.purple-input(@keyup="calculateTurned" :rules='[rules.required]' label="Valor Pagado" v-model="valPayment" type="text" @keypress="isNumber($event)")
          v-col(cols='12' md="6")
            label(style="font-size: 25px; margin-top: 15px;") VUELTO: ${{valTurned}}
          v-col(cols="12")
            v-alert(v-model="alertProductTurned" dismissible dense='' border='left' type='error')
              | {{msgAlertTurned}}
        v-divider
        v-card-actions
          v-spacer
          v-btn.success-btn(text='' @click='makePayment' :loading="loadingMakePayment")
            | FACTURAR
    v-dialog(fullscreen="" v-model='dialogCLient')
      v-card
        registro-cliente-modal(@closeModal="closeModalClient" :clientToEdit="clientToEdit")
    v-snackbar(:timeout='10000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Facturar Mesa {{openTable.number}}  -->  TOTAL: {{total}}
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModalSale')")
            v-icon.primary.rounded mdi-close
      v-card-text(style="padding-top: 0px;")
        v-row(style="margin-top: 20px;")
          v-form(ref='formAddFactura' v-model='validAddFactura' lazy-validation='' style="width: 100%; margin: 10px;")
            v-row
              v-col.py-0(cols="12" md="3")
                v-menu(ref='startMenu1' v-model='startMenu1' :close-on-content-click='false' :nudge-right='40' :return-value.sync='factura.fechaEmision' transition='scale-transition' min-width='290px' offset-y='')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly dense="" :rules='[rules.required]' v-model='computedDateFormattedMomentjs' prepend-icon='mdi-calendar' v-on='on')
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Fecha de Emisión' }}
                  v-date-picker.my-0(:max="currentDate" :rules='[rules.required]' v-model='factura.fechaEmision' no-title='' scrollable='' locale='es-ec' @change="$refs.startMenu1.save(factura.fechaEmision)" color="green lighten-1")
              v-col.py-0(cols="12" md="5")
                v-autocomplete(:rules='[rules.required]' v-model="factura.formapago" required :items='electronicbillingtables ? electronicbillingtables.formaspago : []' return-object item-text="formapago" dense='')
                  template(#label="")
                    span.red--text
                      strong *
                    | {{ ' ' + 'Forma de pago' }}
              v-col.py-0(cols="12" md="4")
                v-text-field(:disabled="(factura.formapago != null ? (factura.formapago.codigo.toString() !== '16' && factura.formapago.codigo.toString() !== '18' && factura.formapago.codigo.toString() !== '19') : true)" v-model="factura.numeroVoucher" required dense='')
                  template(#label="")
                    | {{ ' ' + 'Número Voucher' }}
              v-col.py-0(cols='10' md='5')
                v-autocomplete(dense="" :rules='[rules.required]' v-model='factura.client' :items='clientLst' return-object='return-object' item-text='completeData' label='Cliente')
              v-col.py-0.pl-0.ml-0(cols="2" md="1" style="text-align: center;")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(v-on="on" icon text color="purple darken-3" @click="addNewClient" style='background:#B18BE2;')
                      v-icon(style="font-size: 25px;") mdi-plus
                  span.tooltips Crear Cliente
              v-col(cols="6" md="2" style="padding: 0px;")
                v-checkbox(label="Pendiente de Pago" style="margin: 0px; color: blue;" v-model="factura.pendientepago")
              v-col.py-0(cols="6" md="2")
                v-menu(:disabled="!factura.pendientepago" ref='startMenu2' v-model='startMenu2' :close-on-content-click='false' :nudge-right='40' :return-value.sync='factura.fechapago' transition='scale-transition' min-width='290px' offset-y='')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly :disabled="!factura.pendientepago" dense="" v-model='computedDateFormattedMomentPagojs' prepend-icon='mdi-calendar' v-on='on')
                      template(#label="")
                        | {{ ' ' + 'Fecha de Pago' }}
                  v-date-picker.my-0(:min="currentDate" v-model='factura.fechapago' no-title='' scrollable='' locale='es-ec' @change="$refs.startMenu2.save(factura.fechapago)")
              v-col(cols="12" md="2" style="padding-top: 0px")
                v-btn(color='green' @click="createBillingAccounts" style="width: 100%;" :loading="loadingSave")
                  | FACTURAR
              v-col(cols="12")
                v-tabs(v-model="tabsBilling" grow="" icons-and-text="" height="35px")
                  v-tab(v-if="facturaOneAccount.lstProductos.length > 0" ripple href='#tab-1')   Productos: {{profileLoaded.rucEstablecimieto ? profileLoaded.rucEstablecimieto + ' - ' + profileLoaded.razonSocial : ''}}
                  v-tab(v-if="facturaTwoAccount.lstProductos.length > 0" ripple href='#tab-2')   Productos: {{profileLoaded.rucEstablecimieto2 ? profileLoaded.rucEstablecimieto2 + ' - ' + profileLoaded.razonSocial2 : ''}}
                  v-tab-item(v-if="facturaOneAccount.lstProductos.length > 0" value='tab-1')
                    v-row(style="margin-right: 20px; margin-top: 20px;")
                      v-col(cols="12" md="10" style="padding-bottom: 0px; padding-top: 0px;")
                        v-data-table(style="height: 300px; min-height: 430px; overflow-y: scroll;" :headers="headers" :items="facturaOneAccount.lstProductos" item-key="id" dense fixed-header :loading="cargando" loading-text="Cargando" :footer-props="{\'items-per-page-text':'Productos por página'\, 'items-per-page-options':[10, 20, 30, 50, 100]}" :items-per-page="100")
                          template(v-slot:item.cantidadView="{ item }")
                            p(style="font-size: 12px; font-weight: bold;") {{item.cantidad}}
                          template(v-slot:item.descuentoView="{ item }")
                            p(style="font-size: 12px;") ${{item.descuento}}
                          template(v-slot:item.valorUnitarioView="{ item }")
                            p(style="font-size: 12px;") ${{item.valorUnitario}}
                          template(v-slot:item.totalView="{ item }")
                            p(style="font-size: 12px; font-weight: bold;") ${{item.total}}
                          template(v-slot:item.codView="{ item }")
                            p(style="font-size: 10px;") {{item.producto.codArticle}}
                          template(v-slot:item.nameView="{ item }")
                            p(style="font-size: 10px;") {{item.producto.name}}
                      v-col(cols="12" md="2" style="padding-bottom: 0px; padding-top: 0px;")
                        v-row(style="padding-top: 30px;")
                          v-col(cols="12" md="12" style="padding: 0px; padding-top: 10px;")
                            v-checkbox(label="Nota de Venta" style="margin: 0px; color: red;" v-model="notaVenta1")
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') SUB TOTAL: ${{facturaOneAccount.subtotal}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') IVA {{newTarifaIva}}%: ${{facturaOneAccount.iva12}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') SUB 0%: ${{facturaOneAccount.subcero}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') NO OBJETO DE IVA: ${{facturaOneAccount.noobjiva}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') EXCENTO DE IVA: ${{facturaOneAccount.excentoiva}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') TOTAL DESCUENTO: ${{facturaOneAccount.totdcto}}
                          hr(style="border-color: white; width: 100%; margin: 5px -5px 5px 50px;")
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 16px; font-weight: bold; width: 100px;') TOTAL: ${{facturaOneAccount.total}}
                  v-tab-item(v-if="facturaTwoAccount.lstProductos.length > 0" value='tab-2')
                    v-row(style="margin-right: 20px; margin-top: 20px;")
                      v-col(cols="12" md="10" style="padding-bottom: 0px; padding-top: 0px;")
                        v-data-table(style="height: 300px; min-height: 430px; overflow-y: scroll;" :headers="headers" :items="facturaTwoAccount.lstProductos" item-key="id" dense fixed-header :loading="cargando" loading-text="Cargando" :footer-props="{\'items-per-page-text':'Productos por página'\, 'items-per-page-options':[10, 20, 30, 50, 100]}" :items-per-page="100")
                          template(v-slot:item.cantidadView="{ item }")
                            p(style="font-size: 12px; font-weight: bold;") {{item.cantidad}}
                          template(v-slot:item.descuentoView="{ item }")
                            p(style="font-size: 12px;") ${{item.descuento}}
                          template(v-slot:item.valorUnitarioView="{ item }")
                            p(style="font-size: 12px;") ${{item.valorUnitario}}
                          template(v-slot:item.totalView="{ item }")
                            p(style="font-size: 12px; font-weight: bold;") ${{item.total}}
                          template(v-slot:item.codView="{ item }")
                            p(style="font-size: 10px;") {{item.producto.codArticle}}
                          template(v-slot:item.nameView="{ item }")
                            p(style="font-size: 10px;") {{item.producto.name}}
                      v-col(cols="12" md="2" style="padding-bottom: 0px; padding-top: 0px;")
                        v-row(style="padding-top: 30px;")
                          v-col(cols="12" md="12" style="padding: 0px; padding-top: 10px;")
                            v-checkbox(label="Nota de Venta" style="margin: 0px; color: red;" v-model="notaVenta2")
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') SUB TOTAL: ${{facturaTwoAccount.subtotal}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') IVA {{newTarifaIva}}%: ${{facturaTwoAccount.iva12}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') SUB 0%: ${{facturaTwoAccount.subcero}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') NO OBJETO DE IVA: ${{facturaTwoAccount.noobjiva}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') EXCENTO DE IVA: ${{facturaTwoAccount.excentoiva}}
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 13px;') TOTAL DESCUENTO: ${{facturaTwoAccount.totdcto}}
                          hr(style="border-color: white; width: 100%; margin: 5px -5px 5px 50px;")
                          v-col(cols="12" style="padding: 0px; text-align: right;")
                            label(style='font-size: 16px; font-weight: bold; width: 100px;') TOTAL: ${{facturaTwoAccount.total}}
</template>
<script>
  import moment from 'moment'
  import * as vars from '../../../../vars.json'
  var accounting = require('accounting')
  export default {
    components: {
      RegistroClienteModal: () => import('@/views/dashboard/component/application/RegistroClienteModal'),
    },
    props: {
      openTableBillig: {
        default: function () {
          return {
            secuential: 0,
            number: '',
            observations: '',
            lstProductos: [],
            subtotal: '0.00',
            subcero: '0.00',
            noobjiva: '0.00',
            excentoiva: '0.00',
            totdcto: '0.00',
            ice: '0.00',
            irbpnr: '0.00',
            iva12: '0.00',
            total: '0.00',
            createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
            createDate: moment(new Date()).format('YYYY-MM-DD'),
            waitress: '',
          }
        },
        type: Object,
      },
    },
    data: () => ({
      newTarifaIva: '',
      notaVenta1: false,
      notaVenta2: true,
      cargando: false,
      alertProductTurned: false,
      loadingMakePayment: false,
      banSendFac: '0',
      dialogSaveFac: false,
      valTurned: '0.00',
      valPayment: '',
      msgAlertTurned: '',
      loadingSave: false,
      headers: [
        { text: 'Cant.', value: 'cantidadView', align: 'left', filterable: true, width: '10px' },
        { text: 'Cod.', value: 'codView', align: 'left', filterable: true },
        { text: 'Nombre', value: 'nameView', align: 'left', filterable: true },
        { text: 'Dcto.', value: 'descuentoView', align: 'left', filterable: true },
        { text: 'Val. Unit.', value: 'valorUnitarioView', align: 'left', filterable: true },
        { text: 'Tot.', value: 'totalView', align: 'left', filterable: true },
      ],
      tabsBilling: 'tab-1',
      facturaOneAccount: {
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
      },
      facturaTwoAccount: {
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
      },
      clientToEdit: {
        name: '',
        lastName: '',
        mail: '',
        address: '',
        ruc: '',
        phone: '',
        clientKey: '',
      },
      openTable: {
        secuential: 0,
        number: '',
        observations: '',
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
        createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
        createDate: moment(new Date()).format('YYYY-MM-DD'),
        waitress: '',
      },
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      startMenu1: false,
      startMenu2: false,
      factura: {
        numsecuencial: '',
        client: null,
        porcentajeiva: '',
        cedula: '',
        // cadAutorizacion: moment(new Date()).format('YYYY-MM-DD'),,
        telefono: '',
        email: '',
        direccion: '',
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
        fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
        descripcion: '',
        formapago: null,
        proveedor: null,
        saldo: '0.00',
        numeroVoucher: null,
        pendientepago: false,
        fechapago: moment(new Date()).format('YYYY-MM-DD'),
        observaciones: '',
        mesa: '',
        createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      validAddFactura: true,
      dialogCLient: false,
      total: 0,
    }),
    computed: {
      branchsSelected () {
        return this.$store.state.profile.branchSelected
      },
      computedDateFormattedMomentjs () {
        return this.factura.fechaEmision ? moment(this.factura.fechaEmision).format('DD/MM/YYYY') : ''
      },
      computedDateFormattedMomentPagojs () {
        return this.factura.fechapago ? moment(this.factura.fechapago).format('DD/MM/YYYY') : ''
      },
      electronicbillingtables () {
        return this.$store.state.facturacion.electronicbillingtables
      },
      clientLst () {
        return this.$store.state.client.lstClients
      },
      endClient () {
        return this.$store.state.client.endClient
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
      companyDataLoad2 () {
        return this.$store.state.facturacion.companydata2
      },
    },
    watch: {
      openTableBillig () {
        this.banSendFac = '0'
        if (this.openTableBillig) {
          this.openTable = this.openTableBillig
        }
        this.factura.formapago = {
          codigo: '01',
          fechafin: '',
          fechainicio: '01/01/2013',
          formapago: 'SIN UTILIZACION DEL SISTEMA FINANCIERO',
        }
        this.factura.client = {
          idType: {
            codigo: '07',
            texto: 'VENTA A CONSUMIDOR FINAL*',
          },
          address: 'Consumidor Final',
          clientKey: 'consumidorfinal',
          completeData: 'Consumidor Final | 9999999999999',
          lastName: 'Final',
          mail: '',
          businessName: '',
          name: 'Consumidor',
          phone: '',
          cellphone: '0999999999',
          ide: '9999999999999',
          id: 'consumidorfinal',
          viewName: 'Consumidor Final',
        }
        this.validProductsListData()
      },
      endClient () {
        this.clientSet = this.endClient
        if (this.clientSet !== null) {
          this.factura.client = this.clientSet
        } else {
          this.factura.client = {
            idType: {
              codigo: '07',
              texto: 'VENTA A CONSUMIDOR FINAL*',
            },
            address: 'Consumidor Final',
            clientKey: 'consumidorfinal',
            completeData: 'Consumidor Final | 9999999999999',
            lastName: 'Final',
            mail: '',
            businessName: '',
            name: 'Consumidor',
            phone: '',
            cellphone: '0999999999',
            ide: '9999999999999',
            id: 'consumidorfinal',
            viewName: 'Consumidor Final',
          }
        }
      },
    },
    mounted () {
      this.newTarifaIva = vars.validIva
      this.banSendFac = '0'
      if (this.openTableBillig) {
        this.openTable = this.openTableBillig
      }
      this.electronicbillingtables = this.$store.state.facturacion.electronicbillingtables
      this.clientSet = this.$store.state.client.endClient
      this.factura.client = {
        idType: {
          codigo: '07',
          texto: 'VENTA A CONSUMIDOR FINAL*',
        },
        address: 'Consumidor Final',
        clientKey: 'consumidorfinal',
        completeData: 'Consumidor Final | 9999999999999',
        lastName: 'Final',
        mail: '',
        businessName: '',
        name: 'Consumidor',
        phone: '',
        cellphone: '0999999999',
        ide: '9999999999999',
        id: 'consumidorfinal',
        viewName: 'Consumidor Final',
      }
      this.factura.formapago = {
        codigo: '01',
        fechafin: '',
        fechainicio: '01/01/2013',
        formapago: 'SIN UTILIZACION DEL SISTEMA FINANCIERO',
      }
      this.validProductsListData()
    },
    methods: {
      async guardarFactura () {
        if (this.banSendFac === '0') {
          this.banSendFac = '1'
          setTimeout(() => {
            this.banSendFac = '0'
          }, 1000)
          if (this.$refs.formAddFactura.validate()) {
            if (this.facturaOneAccount.lstProductos.length === 0 && this.facturaTwoAccount.lstProductos.length === 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Para continuar debe agregar los productos.',
              }
              return
            }
            if (parseFloat(this.total) >= 200 && this.factura.client.ide.toString() === '9999999999999') {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Recuerde que a partir de $200 no se puede emitir una factura como CONSUMIDOR FINAL.',
              }
              return
            }
            this.loadingSave = true

            const arrDataSend = []

            if (this.facturaOneAccount.lstProductos.length > 0) {
              const factura1 = {
                numsecuencial: this.factura.numsecuencial,
                client: this.factura.client,
                porcentajeiva: this.factura.porcentajeiva,
                cedula: this.factura.cedula,
                // cadAutorizacion: this.factura.// cadAutorizacion,
                telefono: this.factura.telefono,
                email: this.factura.email,
                direccion: this.factura.direccion,
                lstProductos: this.factura.lstProductos,
                subtotal: this.factura.subtotal,
                subcero: this.factura.subcero,
                noobjiva: this.factura.noobjiva,
                excentoiva: this.factura.excentoiva,
                totdcto: this.factura.totdcto,
                ice: this.factura.ice,
                irbpnr: this.factura.irbpnr,
                iva12: this.factura.iva12,
                total: this.factura.total,
                fechaEmision: this.factura.fechaEmision,
                descripcion: this.factura.descripcion,
                formapago: this.factura.formapago,
                proveedor: this.factura.proveedor,
                saldo: this.factura.saldo,
                numeroVoucher: this.factura.numeroVoucher,
                pendientepago: this.factura.pendientepago,
                fechapago: this.factura.fechapago,
                observaciones: this.factura.observaciones,
                mesa: this.factura.mesa,
                createTime: this.factura.createTime,
                estado: true,
                userProfile: this.profileLoaded,
                branch: this.branchsSelected,
              }
              if (this.notaVenta1) {
                factura1.numsecuencial = this.profileLoaded.estab + '-' + this.profileLoaded.ptoEmi + '-' + this.profileLoaded.secuenciales.notaventa.toString().padStart(9, '0')
              } else {
                factura1.numsecuencial = this.profileLoaded.estab + '-' + this.profileLoaded.ptoEmi + '-' + this.profileLoaded.secuenciales.facventa.toString().padStart(9, '0')
              }
              if (this.companyDataLoad) {
                if (this.companyDataLoad.sendSri) {
                  let userSend = this.$store.state.user.user.email
                  if (userSend.length > 20) {
                    userSend = userSend.substr(0, 19)
                  }
                  await this.$store.dispatch('facturacion/userLoginFacInTo', [userSend, this.profileLoaded.valuidfac])
                  factura1.companyData = this.companyDataLoad
                  factura1.cuenta = '001'
                  factura1.token = this.$store.state.facturacion.tokenUser
                  factura1.sendSri = true
                }
              } else {
                factura1.cuenta = '001'
              }
              factura1.lstProductos = this.facturaOneAccount.lstProductos
              factura1.subtotal = this.facturaOneAccount.subtotal
              factura1.subcero = this.facturaOneAccount.subcero
              factura1.noobjiva = this.facturaOneAccount.noobjiva
              factura1.excentoiva = this.facturaOneAccount.excentoiva
              factura1.totdcto = this.facturaOneAccount.totdcto
              factura1.ice = this.facturaOneAccount.ice
              factura1.irbpnr = this.facturaOneAccount.irbpnr
              factura1.iva12 = this.facturaOneAccount.iva12
              factura1.total = this.facturaOneAccount.total
              factura1.selectedUserUid = this.$store.state.user.selectedUserUid
              factura1.setNotaVenta = this.notaVenta1
              if (this.notaVenta1) {
                const secuencial = this.profileLoaded.secuenciales.notaventa
                arrDataSend.push({
                  noteData: factura1,
                  uidUser: '',
                  secnotesale: secuencial,
                  idOpenTable: this.openTableBillig.id,
                  setNotaVenta: this.notaVenta1,
                })
              } else {
                const secuencial = this.profileLoaded.secuenciales.facventa
                arrDataSend.push({
                  facturaData: factura1,
                  uidUser: '',
                  secfacsale: secuencial,
                  idOpenTable: this.openTableBillig.id,
                  setNotaVenta: this.notaVenta1,
                })
              }
            }

            if (this.facturaTwoAccount.lstProductos.length > 0) {
              const factura2 = {
                numsecuencial: this.factura.numsecuencial,
                client: this.factura.client,
                porcentajeiva: this.factura.porcentajeiva,
                cedula: this.factura.cedula,
                // cadAutorizacion: this.factura.// cadAutorizacion,
                telefono: this.factura.telefono,
                email: this.factura.email,
                direccion: this.factura.direccion,
                lstProductos: this.factura.lstProductos,
                subtotal: this.factura.subtotal,
                subcero: this.factura.subcero,
                noobjiva: this.factura.noobjiva,
                excentoiva: this.factura.excentoiva,
                totdcto: this.factura.totdcto,
                ice: this.factura.ice,
                irbpnr: this.factura.irbpnr,
                iva12: this.factura.iva12,
                total: this.factura.total,
                fechaEmision: this.factura.fechaEmision,
                descripcion: this.factura.descripcion,
                formapago: this.factura.formapago,
                proveedor: this.factura.proveedor,
                saldo: this.factura.saldo,
                numeroVoucher: this.factura.numeroVoucher,
                pendientepago: this.factura.pendientepago,
                fechapago: this.factura.fechapago,
                observaciones: this.factura.observaciones,
                mesa: this.factura.mesa,
                createTime: this.factura.createTime,
                estado: true,
                userProfile: this.profileLoaded,
                branch: this.branchsSelected,
              }
              if (this.notaVenta2) {
                factura2.numsecuencial = this.profileLoaded.estab2 + '-' + this.profileLoaded.ptoEmi2 + '-' + this.profileLoaded.secuenciales2.notaventa.toString().padStart(9, '0')
              } else {
                factura2.numsecuencial = this.profileLoaded.estab2 + '-' + this.profileLoaded.ptoEmi2 + '-' + this.profileLoaded.secuenciales2.facventa.toString().padStart(9, '0')
              }
              if (this.companyDataLoad2) {
                if (this.companyDataLoad2.sendSri) {
                  let userSend = this.$store.state.user.user.email
                  if (userSend.length > 20) {
                    userSend = userSend.substr(0, 19)
                  }
                  await this.$store.dispatch('facturacion/userLoginFacInTo', [userSend, this.profileLoaded.valuidfac])
                  factura2.companyData = this.companyDataLoad2
                  factura2.cuenta = '002'
                  factura2.token = this.$store.state.facturacion.tokenUser
                  factura2.sendSri = true
                }
              } else {
                factura2.cuenta = '002'
              }
              factura2.lstProductos = this.facturaTwoAccount.lstProductos
              factura2.subtotal = this.facturaTwoAccount.subtotal
              factura2.subcero = this.facturaTwoAccount.subcero
              factura2.noobjiva = this.facturaTwoAccount.noobjiva
              factura2.excentoiva = this.facturaTwoAccount.excentoiva
              factura2.totdcto = this.facturaTwoAccount.totdcto
              factura2.ice = this.facturaTwoAccount.ice
              factura2.irbpnr = this.facturaTwoAccount.irbpnr
              factura2.iva12 = this.facturaTwoAccount.iva12
              factura2.total = this.facturaTwoAccount.total
              factura2.selectedUserUid = this.$store.state.user.selectedUserUid
              factura2.setNotaVenta = this.notaVenta2
              if (this.notaVenta2) {
                const secuencial = this.profileLoaded.secuenciales2.notaventa
                arrDataSend.push({
                  noteData: factura2,
                  uidUser: '',
                  secnotesale: secuencial,
                  idOpenTable: this.openTableBillig.id,
                  setNotaVenta: this.notaVenta2,
                })
              } else {
                const secuencial = this.profileLoaded.secuenciales2.facventa
                arrDataSend.push({
                  facturaData: factura2,
                  uidUser: '',
                  secfacsale: secuencial,
                  idOpenTable: this.openTableBillig.id,
                  setNotaVenta: this.notaVenta2,
                })
              }
            }

            if (arrDataSend.length > 0) {
              this.$store.dispatch('factura/setFacturaSaleAccounts', arrDataSend).then(res => {
                this.loadingSave = false
                if (res) {
                  this.loadingSave = false
                  if (res.data) {
                    if (res.data.statusError) {
                      if (!this.profileLoaded.genAutomaticInvoice) {
                        this.dialogError = true
                        this.msgFacError = res.data.messageError
                        return
                      }
                    } else {
                      if (res.data.claveAcceso) {
                        this.factura.claveAcceso = res.data.claveAcceso
                      }
                    }
                  }
                  this.snackbar = {
                    show: true,
                    color: 'green',
                    text: 'Factura agregada de manera correcta.',
                  }
                  const data = {
                    msg: 'SUCCESS',
                    obj: this.factura,
                  }
                  this.$emit('closeModalSale', data)
                } else {
                  this.loadingSave = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de enviar la factura.',
                  }
                }
              }, reject => {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'orange',
                  text: 'Factura generada con inconvenientes.',
                }
                setTimeout(() => {
                  const data = {
                    msg: 'ERROR',
                    obj: this.factura,
                  }
                  this.$emit('closeModalSale', data)
                }, 3000)
              })
            }
          }
        }
      },
      calculateTurned () {
        this.alertProductTurned = false
        this.valTurned = accounting.toFixed(parseFloat(this.valPayment) - parseFloat(this.total), 3)
      },
      makePayment () {
        this.loadingMakePayment = true
        if (this.valPayment) {
          if (this.valPayment.toString() !== '') {
            if (parseFloat(this.valPayment) < parseFloat(this.total)) {
              this.alertProductTurned = true
              this.msgAlertTurned = 'El valor cancelado no puede ser menor al valor a pagar.'
              return
            }
          }
        }
        this.dialogSaveFac = false
        this.loadingMakePayment = false
        this.guardarFactura()
      },
      createBillingAccounts () {
        this.alertProductTurned = false
        if (this.$refs.formAddFactura.validate()) {
          if (this.facturaOneAccount.lstProductos.length === 0 && this.facturaTwoAccount.lstProductos.length === 0) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Para continuar debe agregar los productos.',
            }
            return
          }
          if (parseFloat(this.total) >= 200 && this.factura.client.ide.toString() === '9999999999999') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Recuerde que a partir de $200 no se puede emitir una factura como CONSUMIDOR FINAL.',
            }
            return
          }
          if (this.factura.formapago.codigo.toString() === '01' && !this.factura.pendientepago) {
            this.dialogSaveFac = true
            this.valPayment = ''
            this.valTurned = ''
          } else {
            this.guardarFactura()
          }
        }
      },
      calculateValsOneAccount () {
        let sub = 0
        let iva = 0
        let dcto = 0
        let subcero = 0
        let noobjiva = 0
        let exentoiva = 0
        let valDcto = 0
        for (let i = 0; i < this.facturaOneAccount.lstProductos.length; i++) {
          const valTot = parseFloat(this.facturaOneAccount.lstProductos[i].cantidad * this.facturaOneAccount.lstProductos[i].valorUnitario)
          if (this.facturaOneAccount.lstProductos[i].descuento && this.facturaOneAccount.lstProductos[i].descuento.toString() !== '') {
            valDcto = parseFloat(this.facturaOneAccount.lstProductos[i].descuento)
            sub = sub + parseFloat(valTot) - parseFloat(valDcto)
            iva = iva + ((parseFloat(valTot) - parseFloat(valDcto)) * ((parseFloat(this.facturaOneAccount.lstProductos[i].producto.tarifaiva.valor)) / 100))
            dcto = dcto + valDcto
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot) - parseFloat(valDcto)
            }
          } else {
            sub = sub + parseFloat(valTot)
            iva = iva + ((parseFloat(valTot)) * ((parseFloat(this.facturaOneAccount.lstProductos[i].producto.tarifaiva.valor)) / 100))
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot)
            }
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot)
            }
            if (this.facturaOneAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot)
            }
          }
        }
        const tot = parseFloat(sub) + parseFloat(iva)
        const saldo = parseFloat(accounting.toFixed(tot, 2))
        this.facturaOneAccount.subtotal = accounting.toFixed(sub, 2)
        this.facturaOneAccount.subcero = accounting.toFixed(subcero, 2)
        this.facturaOneAccount.noobjiva = accounting.toFixed(noobjiva, 2)
        this.facturaOneAccount.excentoiva = accounting.toFixed(exentoiva, 2)
        this.facturaOneAccount.totdcto = accounting.toFixed(dcto, 2)
        this.facturaOneAccount.ice = '0.00'
        this.facturaOneAccount.irbpnr = '0.00'
        this.facturaOneAccount.iva12 = accounting.toFixed(iva, 2)
        this.facturaOneAccount.total = accounting.toFixed(tot, 2)
        this.facturaOneAccount.saldo = accounting.toFixed(saldo, 2)
      },
      calculateValsTwoAccount () {
        let sub = 0
        let iva = 0
        let dcto = 0
        let subcero = 0
        let noobjiva = 0
        let exentoiva = 0
        let valDcto = 0
        for (let i = 0; i < this.facturaTwoAccount.lstProductos.length; i++) {
          const valTot = parseFloat(this.facturaTwoAccount.lstProductos[i].cantidad * this.facturaTwoAccount.lstProductos[i].valorUnitario)
          if (this.facturaTwoAccount.lstProductos[i].descuento && this.facturaTwoAccount.lstProductos[i].descuento.toString() !== '') {
            valDcto = parseFloat(this.facturaTwoAccount.lstProductos[i].descuento)
            sub = sub + parseFloat(valTot) - parseFloat(valDcto)
            iva = iva + ((parseFloat(valTot) - parseFloat(valDcto)) * ((parseFloat(this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.valor)) / 100))
            dcto = dcto + valDcto
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot) - parseFloat(valDcto)
            }
          } else {
            sub = sub + parseFloat(valTot)
            iva = iva + ((parseFloat(valTot)) * ((parseFloat(this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.valor)) / 100))
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot)
            }
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot)
            }
            if (this.facturaTwoAccount.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot)
            }
          }
        }
        const tot = parseFloat(sub) + parseFloat(iva)
        const saldo = parseFloat(accounting.toFixed(tot, 2))
        this.facturaTwoAccount.subtotal = accounting.toFixed(sub, 2)
        this.facturaTwoAccount.subcero = accounting.toFixed(subcero, 2)
        this.facturaTwoAccount.noobjiva = accounting.toFixed(noobjiva, 2)
        this.facturaTwoAccount.excentoiva = accounting.toFixed(exentoiva, 2)
        this.facturaTwoAccount.totdcto = accounting.toFixed(dcto, 2)
        this.facturaTwoAccount.ice = '0.00'
        this.facturaTwoAccount.irbpnr = '0.00'
        this.facturaTwoAccount.iva12 = accounting.toFixed(iva, 2)
        this.facturaTwoAccount.total = accounting.toFixed(tot, 2)
        this.facturaTwoAccount.saldo = accounting.toFixed(saldo, 2)
      },
      validProductsListData () {
        this.facturaOneAccount.lstProductos = []
        this.facturaTwoAccount.lstProductos = []
        if (this.openTableBillig) {
          for (let i = 0; i < this.openTableBillig.lstProductos.length; i++) {
            if (this.openTableBillig.lstProductos[i].producto.accountType) {
              if (this.openTableBillig.lstProductos[i].producto.accountType === '001') {
                this.facturaOneAccount.lstProductos.push(this.openTableBillig.lstProductos[i])
              } else {
                this.facturaTwoAccount.lstProductos.push(this.openTableBillig.lstProductos[i])
              }
            } else {
              this.facturaOneAccount.lstProductos.push(this.openTableBillig.lstProductos[i])
            }
          }
        }
        this.total = 0
        if (this.facturaOneAccount.lstProductos.length > 0) {
          this.calculateValsOneAccount()
          this.total = parseFloat(this.total) + parseFloat(this.facturaOneAccount.total)
        }
        if (this.facturaTwoAccount.lstProductos.length > 0) {
          this.calculateValsTwoAccount()
          this.total = parseFloat(this.total) + parseFloat(this.facturaTwoAccount.total)
        }
        this.total = this.total.toFixed(3)
      },
      closeModalClient () {
        this.dialogCLient = false
      },
      addNewClient () {
        this.dialogCLient = true
        this.clientToEdit = {
          idType: {
            codigo: '05',
            texto: 'CEDULA',
          },
          businessName: '',
          name: '',
          lastName: '',
          mail: '',
          address: '',
          ide: '',
          phone: '',
          cellphone: '',
          clientKey: '',
        }
      },
    },
  }
</script>
